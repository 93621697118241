import { createLogger } from "@pfed/dpl-lite";

const FORMAT = ["%c[RAMP.js]", "color: #71BBD8"];

const dpl = createLogger({
  initEventName: "RampjsInit",
  product: "RAMPJS",
  sendInitEvent: false,
  useNavigator: true,
});

function isError(obj: unknown): obj is Error {
  return obj instanceof Error;
}

export function _errorEvent(message: unknown) {
  console.error(message);

  _sendEvent("RampjsError", {
    message: isError(message) ? message.message : message,
  });
}

export function _sendEvent(event: string, data: unknown) {
  dpl.log({ event, payload: { ...(data as object) } });
}

export function _rampLogger(message: unknown) {
  console.log(...FORMAT, message);
}

export function _rampLoggerGroup(group: string, ...logs: string[]) {
  console.groupCollapsed(...FORMAT, group);
  for (const log of logs) {
    console.log(log);
  }
  console.groupEnd();
}
