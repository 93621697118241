import { AY } from "@rampjs/schema";

/**
 * Functions to load a script file
 *
 * @param {string} src script URL
 * @returns {Promise<void>} Promise that resolves when script is loaded
 */
export const loadScript = (src: string) => {
  return new Promise<void>(function (resolve, reject) {
    const timeout = setTimeout(
      reject,
      10000,
      `Timeout exceeded. Could not load ${src}`,
    );
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = src;
    s.async = true;
    s.onerror = function (err) {
      clearTimeout(timeout);
      reject(err);
    };
    s.onload = function () {
      clearTimeout(timeout);
      resolve();
    };
    document.head.appendChild(s);
  });
};

/**
 * Handler for slotRendedEnded event.
 *
 * The function extracts relevant information from the event object, constructs a set of search parameters,
 * and sends a pingback to the specified URL with these parameters.
 *
 * @param event - slotRendedEnded event object.
 * @param pingbackBaseUrl - The base URL to which the pingback should be sent.
 */
const handleSlotRenderEnded = (
  event: googletag.events.SlotRenderEndedEvent,
  pingbackBaseUrl: string,
) => {
  const {
    advertiserId,
    campaignId,
    creativeId,
    isEmpty,
    lineItemId,
    size,
    slot,
  } = event;
  const adUnitName = slot.getAdUnitPath();

  const searchParams: { [key: string]: string } = {
    event_type: "ay_impression",
    adUnitName,
    isEmpty: isEmpty.toString(),
  };

  if (advertiserId) {
    searchParams["advertiserId"] = advertiserId.toString();
  }

  if (campaignId) {
    searchParams["campaignId"] = campaignId.toString();
  }

  if (creativeId) {
    searchParams["creativeId"] = creativeId.toString();
  }

  if (lineItemId) {
    searchParams["lineItemId"] = lineItemId.toString();
  }

  if (size) {
    searchParams["size"] = size.toString();
  }

  firePingbackURl(pingbackBaseUrl, searchParams);
};

/**
 * Sends a pingback request to the specified URL with the given search parameters.
 *
 * @param pingbackBaseUrl - The base URL to which the pingback request will be sent.
 * @param searchParams - An object containing key-value pairs to be appended as search parameters to the URL.
 */
export const firePingbackURl = (
  pingbackBaseUrl: string,
  searchParams: { [key: string]: string },
) => {
  const pingbackUrl = new URL(pingbackBaseUrl);
  for (const [key, value] of Object.entries(searchParams)) {
    pingbackUrl.searchParams.append(key, value);
  }
  fetch(pingbackUrl, { keepalive: true }).catch((error) => {
    console.error("Error sending pingback:", error);
  });
};

export const init = (
  ayConfig: AY.PageOptions,
  targeting: { [key: string]: string | string[] },
) => {
  const googletag = (window.googletag = window.googletag || { cmd: [] });

  const { entity_id: entityId, pingbackBaseUrl } = ayConfig;
  const ayScript = `https://${entityId}.ay.delivery/manager/${entityId}`;

  // fail early if entity_id is not set
  if (!entityId) {
    console.error("entity_id is required for AY to work.");
    return;
  }

  // load ay script (async)
  loadScript(ayScript).catch((error) => console.error("Error loading ", error));

  // send KVP to GAM
  googletag.cmd.push(() => {
    for (const [key, value] of Object.entries(targeting)) {
      googletag.pubads().setTargeting(key, value);
    }
  });

  // fire pingback URL (if provided) on slotRenderEnded event
  if (pingbackBaseUrl) {
    googletag.cmd.push(() => {
      googletag.pubads().addEventListener("slotRenderEnded", (event) => {
        handleSlotRenderEnded(event, pingbackBaseUrl);
      });
    });
  }
};
