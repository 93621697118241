import * as display from "@rampjs/display";
import { displayImpressionEventType } from "@rampjs/display/src/types";
import { Server } from "@rampjs/schema";
import { sendZemantaEvent, track } from "@utils/conversion-tracking";
import type { Block } from "custom-search-ads";
import { version } from "../package.json";
import * as AY from "./ay";
import {
  canPersonalizeAds,
  createIframe,
  fetchServerResponse,
  getScrollHeight,
  getTerms,
  rampJsUnitLoaded,
} from "./core";
import { DEV_MODE } from "./dev_mode";
import * as CSA from "./google_rsonc";
import * as logger from "./logger";
import {
  recordEvent as rampJsRecordEvent,
  _rampJsMonitorTimings,
} from "./performanceMonitoring";

type MessageHeightData = {
  region: string;
  height: number;
  sticky: string;
};

_rampJsMonitorTimings();

const searchParams = new URLSearchParams(window.location.search);
const isDebug = searchParams.get("debug") === "true";

// tracks if a server response was received
let responseReceived: null | number;

let requestCount = 0;

function checkForMissingDivs(
  serverResponse: Server.Response,
  targetDivId: string,
) {
  const elementWithTargetIdExist = document.getElementById(targetDivId);

  if (!elementWithTargetIdExist) {
    const framework = Object.keys(serverResponse.framework).toString();
    const pageId = serverResponse?.page_id || "";
    const url = window.location.href;
    const { partner_id, subid, segment, non_rev_subid } = serverResponse;

    const dplPayload = {
      targetDivId,
      pageId,
      url,
      framework: framework,
      partner_id,
      subid,
      segment,
      non_rev_subid,
    };
    logger._sendEvent("RampJsMissingDiv", dplPayload);
  }
}

export async function _rampJs(config?: Server.Body) {
  if (typeof window === "undefined") {
    return;
  }

  // only allow multiple requests when enableMultipleRequests is set to true
  const enableMultipleRequests = config?.enableMultipleRequests === true;
  if (requestCount > 0 && enableMultipleRequests === false) {
    return;
  }
  requestCount += 1;

  const rampJsInit = performance.now();

  addEventListener("beforeunload", () => {
    logger._sendEvent("RampJsPageUnload", {
      timeBeforeLeaving: Math.round(performance.now()),
      responseReceived,
    });
  });

  const stickyRegions: string[] = [];

  window.addEventListener("message", (event) => {
    if ((event.data as MessageHeightData).region) {
      const regionBlock = document.getElementById(
        (event.data as MessageHeightData).region,
      ) as HTMLDivElement;

      regionBlock.style.height = `${
        (event.data as MessageHeightData).height
      }px`;

      const frame = regionBlock.querySelector("iframe");
      if (frame) {
        frame.style.height = "100%";
        frame.style.overflow = "hidden";
      }

      if (
        (event.data as MessageHeightData).sticky &&
        (event.data as MessageHeightData).sticky === "1"
      ) {
        if (
          stickyRegions.includes((event.data as MessageHeightData).region) ===
          false
        )
          stickyRegions.push((event.data as MessageHeightData).region);

        const setSticky = () => {
          const regionHeight = regionBlock.getBoundingClientRect().height;
          const screenHeight = window.innerHeight;
          regionBlock.style.position = "sticky";
          regionBlock.style.bottom = `${0.65 * screenHeight - regionHeight}px`;
          regionBlock.style.zIndex = "1000";
          regionBlock.style.bottom = "-15%";
        };
        // if the region is not "rampjs_slot1" we check if it exists and make that sticky instead
        // if it doesn't exist then we make the current region sticky
        if ((event.data as MessageHeightData).region !== "rampjs_slot1") {
          const ip1Block = document.getElementById("rampjs_slot1");
          if (!ip1Block) {
            setSticky();
          }
        } else {
          setSticky();
        }
      }
    }
  });

  window.addEventListener("scroll", function () {
    for (let i = 0; i < stickyRegions.length; i++) {
      const regionBlock = document.getElementById(
        stickyRegions[i] as string,
      ) as HTMLDivElement;

      const rect = regionBlock.getBoundingClientRect();
      if (rect.top <= 0) {
        regionBlock.style.position = "static";
      } else {
        regionBlock.style.position = "sticky";
      }
    }
  });

  const trackOnly = config?.trackOnly === true;
  if (!trackOnly) {
    // begin loading Google CSA scripts
    CSA.insertScripts().catch(() => {
      logger._errorEvent("CSA script failed to load");
    });
  }

  if (isDebug) {
    let termString = "";

    searchParams.forEach(function (value, param) {
      if (param.length === 9 && param.toLowerCase().includes("forcekey")) {
        termString = !termString ? value : termString + `, ${value}`;
      }
    });

    logger._rampLoggerGroup(
      "Initialization",
      JSON.stringify({ version, forceKeyTerms: termString, config }, null, 2),
    );
  }

  const terms = config?.terms ?? getTerms();
  const testModeParam = searchParams.get("test_mode")?.toLowerCase() ?? "";
  const testMode =
    testModeParam === "true" || testModeParam === "false"
      ? testModeParam === "true"
      : config?.testMode === true;

  if (config?.referrer && !testMode) {
    logger._rampLoggerGroup(
      "Config Error",
      "The referrer config is only available when testMode is set to true",
    );
    return;
  }
  const referrer =
    testMode && config?.referrer ? config?.referrer : document.URL;

  const opts: Server.Body = {
    ...config,
    pageTitle: document.title,
    personalizedAds: canPersonalizeAds(),
    referrer: referrer,
    requestReferrer: document.referrer,
    terms,
    testMode,
    trackOnly,
  };

  const response = await fetchServerResponse(opts);
  const adServerResponse = (await response.json()) as Server.Response;

  logger._sendEvent("RampJsServerResponse", {
    responseCode: response.status,
    responseText: response.statusText,
    page_id: adServerResponse.page_id,
  });

  responseReceived = Math.round(performance.now());

  //we want to fire an event when the response is not a 200 ok response
  //example how to listen for the event (keep in mind that the listener must
  //be added before we dispatch the event)
  //window.addEventListener("rampjsResponseError", (e) => console.log(e.detail));
  if (!response.ok) {
    const rampjsResponseError = new CustomEvent("rampjsResponseError", {
      detail: { response, responseBody: adServerResponse },
    });
    window.dispatchEvent(rampjsResponseError);
  }

  if (DEV_MODE) {
    console.log("RampJS-Server Response", response, adServerResponse);
  }

  if (isDebug) {
    if (adServerResponse.debug?.gateway) {
      logger._rampLoggerGroup(
        "Gateway Info",
        JSON.stringify(adServerResponse.debug?.gateway, null, 2),
      );
    }
  }
  if (!adServerResponse.framework) {
    logger._rampLoggerGroup(
      "Gateway Error",
      "The domain or segment is not configured correctly",
    );
    return;
  }

  const [respFramework] = Object.keys(adServerResponse.framework);
  const rampJsResponseTime = performance.now();
  let googCsaInit = null;
  let googCsaDuration = null;
  const { partner_id, subid, segment, non_rev_subid } = adServerResponse;

  const isDomReady = new Promise((resolve) => {
    const checkIsDomReady = () => {
      if (
        document.readyState === "interactive" ||
        document.readyState === "complete"
      ) {
        if (terms === "" && getTerms() !== "") {
          console.warn(
            "<meta> tags with terms found after RampJS initialization. To ensure proper functionality, please move the <meta> tags with terms above the RampJS script tag.",
          );
        }
        resolve(null);
      }
    };

    //we need to ensure this function fires on every ready state change, otherwise this will fail under a race condition.
    document.addEventListener("readystatechange", checkIsDomReady);
    checkIsDomReady();
  });

  //dispatch the framework that rampjs is loading
  //window.addEventListener("rampjsFramework", (e) => console.log(e.detail));
  if (adServerResponse.framework) {
    const rampjsFramework = new CustomEvent("rampjsFramework", {
      detail: Object.keys(adServerResponse.framework)[0],
    });
    window.dispatchEvent(rampjsFramework);
  }

  if (adServerResponse.cheq_settings?.allow_negative_audience) {
    const utmSource = searchParams.get("utm_source")?.toLowerCase();
    if (utmSource?.startsWith("taboola")) {
      window.addEventListener("CHEQ4PPC", () => {
        track({ tbid: "1070267" }, "");
      });
    }

    if (utmSource?.startsWith("zemanta")) {
      window.addEventListener("cq_98e2zj4j", () => {
        sendZemantaEvent("64194", "Content_View");
      });
    }
  }

  const csaTimingComplete = new Promise((resolve, reject) => {
    if (adServerResponse.framework.csa && !trackOnly) {
      const { csa } = adServerResponse.framework;

      try {
        const units: Block[] = [];

        if (config?.ignoredPageParams && csa.pageOptions) {
          if (csa.pageOptions.ignoredPageParams) {
            csa.pageOptions.ignoredPageParams += `,${config.ignoredPageParams}`;
          } else {
            csa.pageOptions.ignoredPageParams = config.ignoredPageParams;
          }
        }

        // observe ad units to measure when units are rendered
        const observerConfig = { childList: true };
        const csaObserver = new MutationObserver(function (mutationList) {
          // resolve if mutation was an iframe injected into element
          for (const mutation of mutationList) {
            if (
              mutation.type === "childList" &&
              mutation.addedNodes[0]?.nodeName.toLowerCase() === "iframe"
            ) {
              csaObserver.disconnect();
              resolve(Math.round(performance.now()));
            }
          }
        });

        let numDivsMissing = 0;
        let firstPingbackUrl;
        for (const unit of csa.units) {
          units.push(CSA.parseUnit(unit));
          firstPingbackUrl = unit.callbackUrl;
          checkForMissingDivs(adServerResponse, unit.container);
          // attach mutation observer to each unit
          const adUnitDiv = document.getElementById(unit.container) as Node;
          if (adUnitDiv) {
            csaObserver.observe(adUnitDiv, observerConfig);
          } else {
            numDivsMissing += 1;
          }
        }
        // checks for no CSA adLoadedCallbacks
        if (firstPingbackUrl) {
          if (numDivsMissing == csa.units.length) {
            CSA.handleMissingDivs(firstPingbackUrl);
          } else {
            CSA.checkForNoCallbacks(firstPingbackUrl);
          }
        }

        googCsaInit = Math.round(performance.now());
        CSA.init(csa.pageOptions, units);

        if (isDebug) {
          logger._rampLoggerGroup(
            "Ad Config",
            JSON.stringify(
              { adType: "csa", pageOptions: csa.pageOptions, units },
              null,
              2,
            ),
          );
        }
      } catch (error) {
        logger._errorEvent(error);
      }
    } else {
      reject("No CSA requests made");
    }
    window.setTimeout(function () {
      reject("Timeout waiting for CSA render");
    }, 500);
  });

  if (!adServerResponse.disable_cheq && adServerResponse.page_id) {
    // add Cheq tag
    const script = document.createElement("script");
    script.async = true;
    script.className = "ct_clicktrue_28382";
    script.dataset.ch = "cheq4ppc";
    script.dataset.uvid = adServerResponse.page_id;
    if (adServerResponse.campaign_id) {
      script.dataset.utmCampaign = adServerResponse.campaign_id;
    }
    script.src =
      "https://ob.system1onesource.com/i/35289458b2de2bf5220f730bdbc66486.js";
    document.head.append(script);
  }

  const targeting = {
    test: ["ramp"],
    page_id: adServerResponse.page_id,
    partner_id: adServerResponse.partner_id.toString(),
    segment: adServerResponse.segment || "",
    subid: adServerResponse.subid || "",
    non_rev_subid: adServerResponse.non_rev_subid || "",
    experimentid: adServerResponse.experiment_id || "",
    content_experiment_id: config?.content_experiment_id || "",
    session_id: adServerResponse.session_id || "",
    experiment_type: adServerResponse.experiment_type || "",
    ...opts?.displayTargeting,
  };

  if (isDebug || opts?.testMode) {
    targeting.test = [...targeting.test, "alwaysfill"];
  }

  if (adServerResponse.framework.display) {
    const { pageOptions } = adServerResponse.framework.display;

    const lazyLoadConfig = pageOptions?.gam?.lazy_load_config;

    // initialise display module
    display
      .init({
        gamConfig: {
          collapseEmptyDivs: pageOptions?.gam?.collapse_empty_divs,
          setCentering: pageOptions?.gam?.set_centering,
          setLazyLoadEnabled: pageOptions?.gam?.set_lazy_load_enabled,
          lazyLoadConfig: {
            fetchMarginPercent: lazyLoadConfig?.fetch_margin_percent,
            renderMarginPercent: lazyLoadConfig?.render_margin_percent,
            mobileScaling: lazyLoadConfig?.mobile_scaling,
          },
        },
        targeting,
      })
      .catch((error) => {
        console.error("Error initializing display module", error);
        logger._errorEvent(error);
      });
  }

  await isDomReady;

  if (adServerResponse.framework.display) {
    const addDisplayUnits = () => {
      if (!adServerResponse.framework.display) {
        return;
      }
      const { pageOptions, units } = adServerResponse.framework.display;

      // Get network code
      let networkCode = pageOptions?.gam.system1_gam_account_id || "";
      if (pageOptions?.gam.partner_gam_account_id) {
        networkCode += `,${pageOptions.gam.partner_gam_account_id}`;
      }

      //lets inject the CSS for the advertisement label.
      display.injectAdvertisementCSS();

      // load display ads
      units.forEach((unit) => {
        const { adUnitName, sizes, divId, type, triggers } = unit;

        // lets check if the divId exists
        const adUnitDiv = document.getElementById(divId);

        // the interstitial type does not require a divId and its a fake id
        // we dont need to check for it
        if (!adUnitDiv && type !== "interstitial") {
          //@todo should this be logged into dpl?
          console.log("Rampjs: Expected DivId does not exist", divId);
          return;
        }

        //add the rampjs-label class to the div
        display.addRampJsClass(divId);

        display
          .addAdUnit({
            code: `/${networkCode}/${adUnitName}`,
            sizes,
            divId,
            type,
            triggers,
          })
          .then((adUnit) => {
            // console.log("unit added", adUnit);
            display.enableAds();
            display.refreshAdUnit(adUnit);
          })
          .catch((error) => {
            console.error("Error adding ad unit:", error);
          });
      });

      // set up event listener for display impression pingbacks
      document.addEventListener("displayImpression", ((
        event: displayImpressionEventType,
      ) => {
        // get the slot element id and determine which unit fired the event
        const slotId = event.detail.slot.getSlotElementId();
        units.forEach((unit) => {
          if (slotId == unit.divId) {
            const {
              advertiserId,
              campaignId,
              creativeId,
              isEmpty,
              lineItemId,
              size,
            } = event.detail;
            const pingback = new URL(unit.pingbackUrl);
            pingback.searchParams.append("event_type", "display_impression");
            if (advertiserId)
              pingback.searchParams.append(
                "advertiserId",
                advertiserId.toString(),
              );
            if (campaignId)
              pingback.searchParams.append("campaignId", campaignId.toString());
            if (creativeId)
              pingback.searchParams.append("creativeId", creativeId.toString());
            pingback.searchParams.append("isEmpty", isEmpty.toString());
            if (lineItemId)
              pingback.searchParams.append("lineItemId", lineItemId.toString());
            if (size) pingback.searchParams.append("size", size.toString());
            // Send the pingback asynchronously.
            fetch(pingback, { keepalive: true }).catch((error) => {
              console.error("Error sending pingback:", error);
            });
          }
        });
      }) as EventListener);
    };

    if (config?.manualDisplayInit) {
      // Make the function available to the global scope
      // window.rampJsAddDisplayAds = addDisplayUnits;
      window.addEventListener("RampJsShowDisplayAds", addDisplayUnits, {
        once: true,
      });
    } else {
      addDisplayUnits();
    }
  }

  if (adServerResponse.framework.ay) {
    // assertive yield id
    AY.init(adServerResponse.framework.ay, targeting);
  }

  if (
    adServerResponse.framework.cta ||
    adServerResponse.framework.bing_trends ||
    adServerResponse.framework.serp_cta
  ) {
    const responseType = adServerResponse.framework.cta ||
      adServerResponse.framework.bing_trends ||
      adServerResponse.framework.serp_cta || { styleId: "", blocks: {} };
    if (responseType.styleId !== "") {
      const styleIdEvent = new CustomEvent("rampJsRenderedStyleId", {
        detail: { styleId: responseType.styleId },
      });
      window.dispatchEvent(styleIdEvent);
    }
    for (const [elementId, srcdoc] of Object.entries(responseType.blocks)) {
      const adBlock = document.getElementById(elementId);
      if (!adBlock) {
        checkForMissingDivs(adServerResponse, elementId);
      } else {
        const iframe = createIframe(srcdoc);
        iframe.style.visibility = "hidden";

        iframe.addEventListener("load", () => {
          const scrollHeight = getScrollHeight(iframe);

          if (scrollHeight) {
            // set iframe height to fit content
            iframe.height = scrollHeight.toString();
          } else {
            // PFED-9161 Set iframe height when the iframe is visible in viewport
            const iframeElem = document.querySelector(
              `#${elementId} iframe`,
            ) as HTMLIFrameElement;
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    const scrollHeight = getScrollHeight(iframeElem);
                    iframe.height = scrollHeight?.toString() ?? "150";
                    observer.unobserve(entry.target);
                  }
                });
              },
              {
                rootMargin: "500px",
              },
            );
            observer.observe(iframeElem);
          }
          iframe.style.visibility = "visible";

          // dispatch unit loaded event
          const event = rampJsUnitLoaded(elementId, respFramework || "");
          window.dispatchEvent(event);
        });

        adBlock.appendChild(iframe);
      }
    }
    if (isDebug) {
      logger._rampLoggerGroup(
        "Ad Config",
        JSON.stringify(
          { adType: respFramework, targetDivs: Object.keys(responseType) },
          null,
          2,
        ),
      );
    }
  }

  try {
    const csaTiming = await csaTimingComplete;
    googCsaDuration = Number(csaTiming);
  } catch (e) {
    if (isDebug) {
      logger._rampLoggerGroup(
        "Google CSA timing",
        `Unable to record CSADuration: ${e as string}`,
      );
    }
  }

  rampJsRecordEvent({
    adServerResponse,
    config,
    googCsaDuration,
    googCsaInit,
    non_rev_subid,
    partner_id,
    rampJsInit,
    rampJsResponseTime,
    respFramework,
    segment,
    subid,
    version,
  });

  if (adServerResponse.partner_tracking) {
    try {
      track(adServerResponse.partner_tracking, adServerResponse.page_id);
    } catch (e) {
      logger._errorEvent(e);
    }
  }

  const experiment_id = adServerResponse.experiment_id;
  if (experiment_id) {
    window.rampjs_experiment_id = experiment_id;
  }
}

if (window._rampJs && "q" in window._rampJs) {
  const queued = window._rampJs.q;
  for (let i = 0; i < queued.length; i++) {
    // eslint-disable-next-line prefer-spread, @typescript-eslint/no-unsafe-argument
    void _rampJs.apply(null, queued[i]);
  }
}

Object.assign(globalThis, { _rampJs });
