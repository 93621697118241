{
  "name": "@rampjs/client",
  "version": "1.63.1",
  "private": true,
  "type": "module",
  "main": "./dist/index.js",
  "module": "./dist/index.js",
  "browser": "./dist/ramp.js",
  "types": "./dist/index.d.ts",
  "scripts": {
    "prepare": "pnpm run build",
    "build": "tsup",
    "clean": "rimraf .turbo dist node_modules",
    "deploy": "tsx ./deploy/serverless && tsx ./deploy",
    "deploy:manual": "tsx ./deploy/serverless && tsx ./deploy",
    "deploy:serverless": "tsx ./deploy/serverless",
    "deploy:client": "tsup && tsx ./deploy",
    "dev": "tsup --watch",
    "format:check": "prettier --check --plugin-search-dir=. **/*.{ts,json,md}",
    "lint": "tsc"
  },
  "devDependencies": {
    "@aws-sdk/client-cloudformation": "^3.504.0",
    "@aws-sdk/client-cloudfront": "^3.504.0",
    "@aws-sdk/client-s3": "^3.504.0",
    "@aws-sdk/credential-providers": "^3.504.1",
    "@swc/core": "^1.3.107",
    "@types/custom-search-ads": "workspace:*",
    "@types/google-publisher-tag": "^1.20231211.0",
    "@types/mime-types": "^2.1.4",
    "@types/node": "^18.19.14",
    "@types/semver": "^7.5.6",
    "esbuild": "0.16.7",
    "happy-dom": "^10.11.2",
    "mime-types": "^2.1.35",
    "rimraf": "^4.4.1",
    "serverless": "^3.38.0",
    "tsup": "^6.7.0",
    "tsx": "^4.7.0",
    "typescript": "^4.9.5"
  },
  "dependencies": {
    "@pfed/dpl-lite": "^1.5.0",
    "@pfed/env": "^1.4.0",
    "@rampjs/display": "workspace:*",
    "@rampjs/schema": "workspace:*",
    "@utils/conversion-tracking": "workspace:*",
    "commander": "^10.0.1",
    "semver": "^7.5.4",
    "zod": "^3.22.4"
  },
  "optionalDependencies": {
    "@rollup/rollup-linux-x64-gnu": "^4.18.0"
  }
}
