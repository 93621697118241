import { Server } from "@rampjs/schema";
import { DEV_MODE } from "./dev_mode";
import * as logger from "./logger";

type RampJsPerfTiming = {
  clientTotalDuration?: number;
  clientDnsDuration?: number;
  clientConnectDuration?: number;
  clientRequestDuration?: number;
  // this value represents the time since the page loaded and when the script was requested at
  // for example if the call happened 2 seconds after the page loaded.
  clientFetchStart?: number;
  serverTotalDuration?: number;
  serverDnsDuration?: number;
  serverConnectDuration?: number;
  // this value represents the time since the page loaded and when the server call was queued at
  // for example if the call happened 2 seconds after the page loaded.
  serverFetchStart?: number;
  serverRequestDuration?: number;
  framework?: string;
  rampJsInit?: number;
  rampJsResponseTime?: number;
  region?: string;
  gatewayDuration?: number;
  gatewayUrl?: string;
  gatewayHeaders?: Record<string, string>;
  gatewayStatus?: number;
  googCsaInit?: number | undefined | null;
  googCsaDuration?: number | undefined | null;
};

type RampJsServerPerfTiming = {
  serverTotalDuration?: number;
  serverDnsDuration?: number;
  serverConnectDuration?: number;
  serverFetchStart?: number;
  serverRequestDuration?: number;
};

//all our known timings
let RampJsTimings: Array<object> = [];
let initialLoad = false;
const rampJsVersion = {} as { version: string };
const rampJsConfigs = {} as { config: Server.Body };
const rampJsPartnerId = {} as { partner_id: number };
const searchParams = new URLSearchParams(window.location.search);
const isDebug = searchParams.get("debug") === "true";
const rampJsSubId = {} as { subid?: string };
const rampJsSegment = {} as { segment: string };
const rampJsNonRevSubid = {} as { non_rev_subid?: string };

export function _rampJsMonitorTimings() {
  const input = process.env.RAMPJS_SERVER_URL as string;

  const observer = new PerformanceObserver(
    (list: PerformanceObserverEntryList) => {
      list.getEntries().forEach((entry: PerformanceEntry) => {
        const resource = entry as PerformanceResourceTiming;
        if (resource.name.includes(input)) {
          if (DEV_MODE) {
            console.log("RampJS-Server Performance fetch Object", resource);
          }
          const perfTimingPayload = {} as RampJsServerPerfTiming;
          //duration represents the total time from dns lookup -> handshake -> processing
          //redirects -> fetching data
          perfTimingPayload.serverTotalDuration = Math.round(resource.duration);

          //this is the total time it takes for the DNS, this value can be zero,
          //when cache is used.
          perfTimingPayload.serverDnsDuration = Math.round(
            resource.domainLookupEnd - resource.domainLookupStart,
          );

          //this is the time for the handshake when talking about SSL but also time to create
          //the connection.
          perfTimingPayload.serverConnectDuration = Math.round(
            resource.connectEnd - resource.connectStart,
          );
          if (!initialLoad) {
            perfTimingPayload.serverFetchStart = Math.round(
              resource.fetchStart,
            );
          }

          //this includes only the request time
          perfTimingPayload.serverRequestDuration = Math.round(
            resource.responseEnd - resource.requestStart,
          );

          RampJsTimings.push(perfTimingPayload);
          logEvent();
        }
      });
    },
  );

  observer.observe({ type: "resource", buffered: false });
}

interface RampJsEvent {
  adServerResponse: Server.Response;
  config?: Server.Body;
  googCsaDuration?: number | null;
  googCsaInit?: number | null;
  non_rev_subid?: string;
  partner_id: number;
  respFramework?: string;
  rampJsInit: number;
  rampJsResponseTime: number;
  segment: string;
  subid?: string;
  version: string;
}

export function recordEvent(rampJsEvent: RampJsEvent) {
  rampJsVersion.version = rampJsEvent.version;
  rampJsConfigs.config = rampJsEvent.config ?? ({} as Server.Body);
  rampJsPartnerId.partner_id = rampJsEvent.partner_id;
  const perfTimingPayload: RampJsPerfTiming = {};
  const perfResourceTiming: PerformanceResourceTiming[] =
    performance.getEntriesByType("resource") as PerformanceResourceTiming[];
  rampJsSubId.subid = rampJsEvent.subid;
  rampJsSegment.segment = rampJsEvent.segment;
  rampJsNonRevSubid.non_rev_subid = rampJsEvent.non_rev_subid;

  /**
   * Explaination of how the performance timing headers work.
   *
   * Duration: is the total time it took for a request to occure. This
   * includes DNS lookup, following redirects, making the fetch to the final
   * url and waiting for the results.
   *
   * requestStart: is a readonly property which returns a timestamp immediately before the
   * browser starts requesting the resource from the server/cache/local resoruce.
   *
   */

  for (const resource of perfResourceTiming) {
    if (
      resource.name.includes("rampjs") &&
      resource.initiatorType === "script"
    ) {
      if (!initialLoad) {
        if (DEV_MODE) {
          console.log("RampJS-Client Performance Object", resource);
        }

        perfTimingPayload.clientTotalDuration = Math.round(resource.duration);
        perfTimingPayload.clientDnsDuration = Math.round(
          resource.domainLookupEnd - resource.domainLookupStart,
        );
        perfTimingPayload.clientConnectDuration = Math.round(
          resource.connectEnd - resource.connectStart,
        );

        perfTimingPayload.clientFetchStart = Math.round(resource.fetchStart);

        //https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/fetchStart
        perfTimingPayload.clientRequestDuration = Math.round(
          resource.responseEnd - resource.requestStart,
        );
      }
    }
  }

  if ("dplData" in rampJsEvent.adServerResponse) {
    perfTimingPayload.framework = rampJsEvent.respFramework;
    if (!initialLoad) {
      perfTimingPayload.rampJsInit = Math.round(rampJsEvent.rampJsInit);
    }
    perfTimingPayload.rampJsResponseTime =
      Math.round(rampJsEvent.rampJsResponseTime) -
      Math.round(rampJsEvent.rampJsInit);
    perfTimingPayload.region = rampJsEvent.adServerResponse.region;
    perfTimingPayload.gatewayDuration = Math.round(
      rampJsEvent.adServerResponse.dplData?.gatewayDuration ?? 0,
    );
    perfTimingPayload.googCsaInit = rampJsEvent.googCsaInit;
    perfTimingPayload.googCsaDuration = rampJsEvent.googCsaDuration;
    RampJsTimings.push(perfTimingPayload);
    logEvent();
  }
}

function logEvent() {
  if (DEV_MODE) {
    console.log(
      "RampJS logEvent Called - ",
      RampJsTimings.length,
      RampJsTimings,
    );
  }

  if (RampJsTimings.length != 2) {
    return;
  }

  const dplPayload = {
    ...RampJsTimings[0],
    ...RampJsTimings[1],
    ...rampJsVersion,
    ...rampJsConfigs,
    ...rampJsPartnerId,
    ...rampJsSubId,
    ...rampJsSegment,
    ...rampJsNonRevSubid,
  };

  if (DEV_MODE) {
    console.log("RampJS timing data sent...", dplPayload);
  }
  if (isDebug) {
    logger._rampLoggerGroup(
      "Performance Timing",
      JSON.stringify({ ...RampJsTimings[0], ...RampJsTimings[1] }, null, 2),
    );
  }

  //remove the element
  RampJsTimings = [];
  logger._sendEvent("RampJsTimings", dplPayload);
  initialLoad = true;
}
