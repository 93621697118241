// src/tracking.ts
var sendFBEvent = (id, event, ldu, eventID) => {
  if (typeof fbq === "undefined") {
    !function(f, b, e, v, n, t, s) {
      if (f.fbq)
        return;
      n = f.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq)
        f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = true;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    }(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", id);
    if (ldu) {
      fbq("dataProcessingOptions", ["LDU"], 1, 1e3);
    } else {
      fbq("dataProcessingOptions", []);
    }
    fbq("track", "PageView");
  }
  if (event) {
    fbq(
      "trackSingleCustom",
      id,
      event,
      { currency: "USD", value: 0 },
      { eventID }
    );
  }
};
var sendTikTokEvent = (id, event, eventID) => {
  !function(w, d, t) {
    w.TiktokAnalyticsObject = t;
    var ttq2 = w[t] = w[t] || [];
    ttq2.methods = [
      "page",
      "track",
      "identify",
      "instances",
      "debug",
      "on",
      "off",
      "once",
      "ready",
      "alias",
      "group",
      "enableCookie",
      "disableCookie"
    ], ttq2.setAndDefer = function(t2, e) {
      t2[e] = function() {
        t2.push([e].concat(Array.prototype.slice.call(arguments, 0)));
      };
    };
    for (var i = 0; i < ttq2.methods.length; i++)
      ttq2.setAndDefer(ttq2, ttq2.methods[i]);
    ttq2.instance = function(t2) {
      for (var e = ttq2._i[t2] || [], n = 0; n < ttq2.methods.length; n++)
        ttq2.setAndDefer(e, ttq2.methods[n]);
      return e;
    }, ttq2.load = function(e, n) {
      var i2 = "https://analytics.tiktok.com/i18n/pixel/events.js";
      ttq2._i = ttq2._i || {}, ttq2._i[e] = [], ttq2._i[e]._u = i2, ttq2._t = ttq2._t || {}, ttq2._t[e] = +/* @__PURE__ */ new Date(), ttq2._o = ttq2._o || {}, ttq2._o[e] = n || {};
      var o = document.createElement("script");
      o.type = "text/javascript", o.async = true, o.src = i2 + "?sdkid=" + e + "&lib=" + t;
      var a = document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(o, a);
    };
    ttq2.load(id);
    ttq2.page();
  }(window, document, "ttq");
  if (event && typeof ttq?.track === "function") {
    ttq.track(event, {}, { event_id: eventID });
  }
};
var sendOutbrainEvent = (id, event) => {
  !function(_window, _document) {
    var OB_ADV_ID = id;
    if (_window.obApi) {
      var toArray = function(object) {
        return Object.prototype.toString.call(object) === "[object Array]" ? object : [object];
      };
      _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(
        toArray(OB_ADV_ID)
      );
      return;
    }
    var api = _window.obApi = function() {
      api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
    };
    api.version = "1.1";
    api.loaded = true;
    api.marketerId = OB_ADV_ID;
    api.queue = [];
    var tag = _document.createElement("script");
    tag.async = true;
    tag.src = "//amplify.outbrain.com/cp/obtp.js";
    tag.type = "text/javascript";
    var script = _document.getElementsByTagName("script")[0];
    script.parentNode.insertBefore(tag, script);
  }(window, document);
  obApi("track", "PAGE_VIEW");
  if (event) {
    obApi("track", event);
  }
};
var sendGoogleEvent = (propertyId1, propertyId2, conversionId, pageId) => {
  if (typeof gtag === "undefined") {
    let gtag2 = function() {
      dataLayer.push(arguments);
    };
    var gtag = gtag2;
    const script = document.createElement("script");
    document.head.appendChild(script);
    script.src = `https://www.googletagmanager.com/gtag/js?id=${propertyId1}`;
    window.dataLayer = window.dataLayer || [];
    gtag2("js", /* @__PURE__ */ new Date());
    if (propertyId1) {
      gtag2("config", propertyId1, { send_page_view: true });
    }
    if (propertyId2) {
      gtag2("config", propertyId2, { send_page_view: true });
    }
  }
  if (conversionId) {
    gtag("event", "conversion", {
      send_to: conversionId,
      transaction_id: pageId
    });
  }
};
var sendSnapchatEvent = (id, event, eventID) => {
  (function(e, t, n) {
    if (e.snaptr)
      return;
    var a = e.snaptr = function() {
      a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
    };
    a.queue = [];
    var s = "script";
    var r = t.createElement(s);
    r.async = true;
    r.src = n;
    var u = t.getElementsByTagName(s)[0];
    u.parentNode.insertBefore(r, u);
  })(window, document, "https://sc-static.net/scevent.min.js");
  snaptr("init", id);
  snaptr("track", "PAGE_VIEW");
  if (event) {
    snaptr("track", event, { client_deduplication_id: eventID });
  }
};
var sendTaboolaEvent = (id, event) => {
  if (typeof window._tfa === "undefined") {
    !function(t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    }(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      `//cdn.taboola.com/libtrc/unip/${id}/tfa.js`,
      "tb_tfa_script"
    );
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "page_view" });
  }
  if (event) {
    window._tfa.push({ notify: "event", name: event });
  }
};
var sendZemantaEvent = (id, event) => {
  !function(_window, _document) {
    var ZEM_TAG_ID = id;
    if (_window.zemApi) {
      var toArray = function(object) {
        return Object.prototype.toString.call(object) === "[object Array]" ? object : [object];
      };
      _window.zemApi.marketerId = toArray(_window.zemApi.marketerId).concat(
        toArray(ZEM_TAG_ID)
      );
      return;
    }
    var api = _window.zemApi = function() {
      api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
    };
    api.version = "1.0";
    api.loaded = true;
    api.marketerId = ZEM_TAG_ID;
    api.queue = [];
    var tag = _document.createElement("script");
    tag.async = true;
    tag.src = "//js-tag.zemanta.com/zcpt.js";
    tag.type = "text/javascript";
    var script = _document.getElementsByTagName("script")[0];
    script.parentNode.insertBefore(tag, script);
  }(window, document);
  zemApi("track", "PAGE_VIEW");
  if (event) {
    zemApi("track", event);
  }
};
var track = (partner_tracking, pageId) => {
  if (typeof window === "undefined") {
    return;
  }
  if (partner_tracking.fbid) {
    try {
      sendFBEvent(
        partner_tracking.fbid,
        partner_tracking.fb_event,
        partner_tracking.fb_ldu,
        pageId
      );
    } catch (error) {
      throw new Error(`Error with Facebook pixel code: ${error}`);
    }
  }
  if (partner_tracking.ttid) {
    try {
      sendTikTokEvent(partner_tracking.ttid, partner_tracking.tt_event, pageId);
    } catch (error) {
      throw new Error(`Error with Tiktok pixel code: ${error}`);
    }
  }
  if (partner_tracking.obid) {
    try {
      sendOutbrainEvent(partner_tracking.obid, partner_tracking.ob_event);
    } catch (error) {
      throw new Error(`Error with Outbrain pixel code: ${error}`);
    }
  }
  if (partner_tracking.gamid || partner_tracking.ga4mid) {
    try {
      sendGoogleEvent(
        partner_tracking.gamid,
        partner_tracking.ga4mid,
        partner_tracking.ga_event,
        pageId
      );
    } catch (error) {
      throw new Error(`Error with Google pixel code: ${error}`);
    }
  }
  if (partner_tracking.scid) {
    try {
      sendSnapchatEvent(
        partner_tracking.scid,
        partner_tracking.sc_event,
        pageId
      );
    } catch (error) {
      throw new Error(`Error with Snapchat pixel code: ${error}`);
    }
  }
  if (partner_tracking.tbid) {
    try {
      sendTaboolaEvent(partner_tracking.tbid, partner_tracking.tb_event);
    } catch (error) {
      throw new Error(`Error with Taboola pixel code: ${error}`);
    }
  }
};
export {
  sendZemantaEvent,
  track
};
