interface Options {
  domain?: string;
  maxAge?: number;
  path?: string;
}

export function get(name: string) {
  const cookies = document.cookie.split("; ");
  const searchString = `${encodeURIComponent(name)}=`;
  const start = searchString.length;

  const predicate = (value: string) => value.startsWith(searchString);
  const value = cookies.find(predicate)?.substring(start);

  if (value) {
    return decodeURIComponent(value);
  }
}

export function set(name: string, value: string, options?: Options) {
  const attributes = [];

  name = encodeURIComponent(name);
  value = encodeURIComponent(value);

  if (options?.domain) {
    attributes.push(`Domain=${options.domain}`);
  }

  if (options?.maxAge) {
    attributes.push(`Max-Age=${options.maxAge}`);
  }

  if (options?.path) {
    attributes.push(`Path=${options.path}`);
  }

  document.cookie = attributes.length
    ? `${name}=${value}; ${attributes.join("; ")}`
    : `${name}=${value}`;
}

export function del(name: string) {
  set(name, "", { maxAge: -1 });
}
